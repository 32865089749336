/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "ClearSans";
  font-style: normal;
  font-weight: normal;
  src: local("./assets/fonts/ClearSans-Regular.woff"),
    url("./assets/fonts/ClearSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Play" !important;
  font-style: normal;
  font-weight: normal;
  src: local("./assets/fonts/play-v17-latin-regular.woff"),
    url("./assets/fonts/play-v17-latin-regular.woff") format("woff");
}

body {
  font-family: "ClearSans" !important;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.error {
  color: var(--ion-color-danger);
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.rounded {
  width: 45px;
  height: 45px !important;
  border-radius: 50%;
  background-color: var(--ion-color-secondary);
  // color: #000;
}

ion-input {
  border: 1px solid grey;
  border-radius: 5px;
  --padding-start: 10px !important;
  --padding-end: 10px !important;
}
.logs {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 99;
  table {
    border: 1px solid #fff;
    margin: 0 auto;
    width: 100%;
    table-layout: auto;
    height: 0;
    tr:nth-child(odd) {
      background-color: #ececec;
      &:nth-child(1) {
        font-weight: bold;
      }
    }
    td {
      border: 1px solid #fff;
      padding: 5px 20px;
      color: #3b3b3b;

      &:nth-child(1),
      &:nth-child(2) {
        white-space: nowrap;
        width: 1%;
      }
    }
  }
}
.slide-down {
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}
.slide-up {
  // height: 0;
  max-height: 200px;
  overflow-y: scroll;
  transition: max-height 0.15s ease-in;
}

.no-border {
  border: 0;
}
.bottom-actions {
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid lightgrey;
  z-index: 9;
  background: #fff;
}
.top-shadow {
  box-shadow: 0px -1px 5px #cecece;
}

:root {
  --ion-font-family: ClearSans;
  a: 
    {
      color: var(--ion-color-secondary)
    }
  .bitvox-theme {
    --ion-color-primary: rgb(254, 218, 90);
    // --ion-color-secondary: rgb(60, 60, 59);
    // --ion-color-secondary: rgb(84, 89, 95);
    --ion-color-secondary: rgb(189, 189, 188);
    // --ion-color-light: rgb(255, 255, 255);
    --ion-toolbar-minheight: 500;
    // a:link:var(--ion-color-secondary);
    // a: 
    // {
    //   link: var(--ion-color-secondary)
    // }
  }
}
